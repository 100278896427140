export function getFutureDates(inputDate: Date, dayOfWeek: number, weeks: number) {
  const dates = [];

  // @ts-ignore
  const targetDayIndex = dayOfWeek;

  if (targetDayIndex === undefined) {
    throw new Error("Invalid day of the week");
  }

  // Get the current day of the week
  const currentDayIndex = inputDate.getDay();

  // Calculate the number of days to add to get the next target day
  let daysToAdd = targetDayIndex - currentDayIndex;
  if (daysToAdd < 0) {
    daysToAdd += 7;
  }

  for (let i = 0; i < weeks; i++) {
    const nextDate = new Date(inputDate);
    nextDate.setDate(inputDate.getDate() + daysToAdd);
    dates.push(nextDate);
    daysToAdd += 7;
  }

  return dates;
}

export function formatDate(date: Date) {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const day = String(date.getDate()).padStart(2, "0");
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

/**
 * getFutureTimezone calculates the timezone for any given date
 * @param date - The date to calculate the timezone for
 * @returns The timezone for the given date
 */
export function getFutureTimezone(date: string, currentUserTimezone: string) {
  // Format the last date to get its timezone abbreviation
  const formatter = new Intl.DateTimeFormat("en-GB", {
    timeZone: currentUserTimezone,
    timeZoneName: "short",
  });

  const parts = formatter.formatToParts(new Date(date));

  const timeZonePart = parts.find(part => part.type === "timeZoneName");

  if (timeZonePart) {
    // Convert lastDate.event_date to a Date object in the correct timezone
    const eventDate = new Date(date);

    // Get the timezone offset in minutes and convert to hours
    const timezoneOffsetMinutes = new Date(eventDate).getTimezoneOffset();
    const timezoneOffsetHours = -timezoneOffsetMinutes / 60;

    return {
      timezone: currentUserTimezone,
      short_timezone: timeZonePart.value,
      timezone_offset: timezoneOffsetHours,
    };
  }

  const userDate = new Date(date);
  const userTimezone = new Intl.DateTimeFormat("en-GB", { timeZone: currentUserTimezone }).resolvedOptions().timeZone;

  // If the timezone abbreviation is not found, return the user's timezone
  return {
    timezone: currentUserTimezone,
    short_timezone: userTimezone,
    timezone_offset: new Date(userDate).getTimezoneOffset() / 60,
  };
}
