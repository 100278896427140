import { defineStore } from "pinia";
import api from "@/api/api";
import router from "@/router";
import { User } from "@/types";
import { userHasRole } from "@/utils/roles";
import { getTimezoneOffset } from "date-fns-tz";

export const useUserStore = defineStore("user", {
  state: () => {
    return {
      user: {} as User,
      isUserLoading: true,
      isUserSubscribed: false,
      isUserOnboarded: false,
      timezone: "",
      short_timezone: "",
      timezone_offset: 0,
      adminRoles: [
        {
          name: "admin",
          display_name: "Admin",
        },
        {
          name: "courses-admin",
          display_name: "Course Admin",
        },
        {
          name: "clubs-admin",
          display_name: "Clubs Admin",
        },
        {
          name: "users-admin",
          display_name: "Users Admin",
        },
      ],
      facilitatorRoles: ["certified-facilitator", "advanced-facilitator-trainee", "advanced-facilitator"],
      trainerRoles: ["l4a-trainer", "trainer-organisation"],
      orgRoles: ['trainer-organisation', 'facilitator-organisation'],
    };
  },
  getters: {
    getUser: state => state.user,
    userIsAdmin: state => state.adminRoles.some(role => state.user.roles.some(userRole => userRole.name === role.name)),
  },
  actions: {
    async fetchUser() {
      try {
        this.isUserLoading = true;
        const {
          data: { user },
        } = await api.auth.getProfile();

        this.user = user;
        this.timezone = user.timezone ? user.timezone : "Europe/London";

        if (userHasRole(this.user.roles, ["admin", "course_admin", "clubs_admin", "users_admin"])) {
          this.isUserSubscribed = true;
        }

        if (this.user.subscription_status === "active") {
          this.isUserSubscribed = true;
        }

        if (this.user.has_onboarded) {
          this.isUserOnboarded = true;
        }

        /** Set Timezone */
        const date = new Date();
        const timezoneAbbrv = new Intl.DateTimeFormat("en-GB", { dateStyle: "short", timeStyle: "long", timeZone: this.timezone }).format(date);

        this.short_timezone = timezoneAbbrv.split(" ")[2];

        // Get timezone offset between UTC and the user's timezone
        this.timezone_offset = getTimezoneOffset(this.timezone) / 1000 / 60;
        console.log("User timezone:", this.timezone);
      } catch (e) {
        const currentRoutePath = router.currentRoute.value.path;

        const noRedirectRoutes = ["/signup", "/signup/redirect", "/redirect"];
        if (noRedirectRoutes.includes(currentRoutePath)) {
          return;
        }
      } finally {
        this.isUserLoading = false;
      }
    },
    async changeDevRole(role: number) {
      await api.users.updateDevRole(this.user.id, role);
      await this.fetchUser();
    },
  },
});
