export default {
  "new-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ny event"])},
  "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hjemmeside"])},
  "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fællesskab"])},
  "email-marketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailmarketing"])},
  "email-preferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailpræferencer"])},
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oversigt"])},
  "my-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min profil"])},
  "account-security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontosikkerhed"])},
  "add-a-new-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj en ny bruger"])},
  "user-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugerprofil"])},
  "user-groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugergrupper"])},
  "user-group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugergruppe"])},
  "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurser"])},
  "course-prerequisites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursusforudsætninger"])},
  "course-templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursusskabeloner"])},
  "add-a-new-template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj en ny skabelon"])},
  "edit-course-template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger kursusskabelon"])},
  "training-events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undervisningsevents"])},
  "add-a-new-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj en ny event"])},
  "training-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undervisningsevent"])},
  "user-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugerbestilling"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log af"])},
  "resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ressourcer"])},
  "upload-Resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload ressource"])},
  "resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ressource"])},
  "edit-Resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger ressource"])},
  "discounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rabatter"])},
  "add-a-new-Discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj en ny rabat"])},
  "add-brick-club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj Brick Club"])},
  "edit-Discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger rabat"])},
  "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rabat"])},
  "certificates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certifikater"])},
  "brick-clubs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brick Clubs"])},
  "view-club-participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis Brick Club-medlem"])},
  "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sammenfatning"])},
  "course-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kursusbeskrivelse"])},
  "registered-attendees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["registrerede deltagere"])},
  "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fakturering"])},
  "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["individuelt"])},
  "course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kursus"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alle"])},
  "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aflyst"])},
  "scheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["planlagt"])},
  "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["udkast"])},
  "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gennemført"])},
  "confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bekræftet"])},
  "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["offentliggjort"])},
  "no-registrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ingen registreringer"])},
  "minimum-not-met": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minimum ikke opfyldt"])},
  "in review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gennemgås"])},
  "unpublished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ikke offentliggjort"])},
  "event-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eventnavn"])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["handlinger"])},
  "training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["undervisning"])},
  "trainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["underviser"])},
  "course-template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kursusskabelon"])},
  "course-level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kursusniveau"])},
  "course-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kursustype"])},
  "delivery-method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["leveringsmetode"])},
  "delivery-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["leveringsdato"])},
  "available-countries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tilgængelige lande"])},
  "date-and-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dato og klokkeslæt"])},
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sted"])},
  "training-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["undervisningslink"])},
  "event-owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["event-ejer"])},
  "presenters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oplægsholdere"])},
  "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pris"])},
  "payment-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["betalingslink"])},
  "payment-gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalings-gateway"])},
  "attendance-limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["deltagergrænse"])},
  "maximum-attendees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maksimalt antal deltagere"])},
  "minimum-attendees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mindste antal deltagere"])},
  "waiting-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["venteliste"])},
  "go-live": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dato for offentliggørelse af denne event"])},
  "registration-cut-off-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sidste dato for registrering"])},
  "start-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["starttidspunkt"])},
  "end-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sluttidspunkt"])},
  "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["region"])},
  "organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["organisation"])},
  "payment-method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["betalingsmetode"])},
  "payment-provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["betalingsplatform"])},
  "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["betalt"])},
  "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gennemført"])},
  "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vis"])},
  "event-submission-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tak fordi du indsendte en event. Play Included vil gennemgå eventen og offentliggøre den på hjemmesiden. Ved events, hvor der kræves betaling af licensgebyr: Play Included vil nu udstede en faktura til betaling. Kurset vil blive offentliggjort på hjemmesiden på den dato, hvor den \"går i luften\", når betalingen er modtaget. Ved events, hvor der ikke kræves betaling af licensgebyr: Play Included vil godkende eventen og offentliggøre den på hjemmesiden på den dato, hvor den er indstillet til at \"gå i luften\"."])},
  "this-event-is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne event er"])},
  "course-feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kursusfeedback"])},
  "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bedømmelse"])},
  "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["feedback"])},
  "welcome-back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velkommen tilbage!"])},
  "your-training-events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dine undervisningsevents"])},
  "submit-event-for-approval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indsend event for at få den godkendt"])},
  "edit-training-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger undervisningsevent"])},
  "save-training-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gem undervisningsevent"])},
  "confirm-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekræft event"])},
  "publish-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offentliggør event nu"])},
  "unpublish-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuller offentliggørelse af event"])},
  "cancel-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aflys event"])},
  "change-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger pris"])},
  "add-a-presenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj en oplægsholder"])},
  "add-another-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj en anden dato"])},
  "select-available-countries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg tilgængelige lande"])},
  "make-training-event-go-live": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret live-undervisningsevent"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slet"])},
  "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fjern"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aflys"])},
  "save-changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gem ændringer"])},
  "delete-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slet konto"])},
  "change-your-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["skift adgangskode"])},
  "add-session-note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj nyt sessionsnotat"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gem"])},
  "add-existing-participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tilføj eksisterende deltager"])},
  "save-participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gem deltager"])},
  "edit-brick-club-participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rediger Brick Club-deltager"])},
  "delete-brick-club-participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slet Brick Club-deltager"])},
  "add-new-participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tilføj ny deltager"])},
  "first-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fornavn"])},
  "last-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["efternavn"])},
  "email-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadresse til Play Included-konto"])},
  "email-address-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne ændring kræver, at du logger ind igen"])},
  "your-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dine roller"])},
  "your-roles-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast hvert job på en ny linje"])},
  "bio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["profil"])},
  "facilitator-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["facilitator-indstillinger"])},
  "trainer-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["underviser-indstillinger"])},
  "register-of-practitioners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register over fagfolk"])},
  "facilitator-public-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne profil er på nuværende tidspunkt ikke synlig på hjemmesiden. Slå denne indstilling til, hvis den skal kunne ses på hjemmesiden."])},
  "public-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Når den er slået til, vil din profil være synlig på"])},
  "public-message-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hvilket omfatter følgende: din organisation, dit land, dine roller og aldersgruppen på medlemmer, du arbejder med, samt et link til dine Brick Clubs."])},
  "works-with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbejder med"])},
  "trainer-public-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne profil er på nuværende tidspunkt ikke synlig på hjemmesiden. Slå denne indstilling til, hvis du vil gerne gøre den synlig på hjemmesiden"])},
  "language-and-region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sprog og region"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["by"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sprog"])},
  "timezone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tidszone"])},
  "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kode"])},
  "promotion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kampagne"])},
  "starts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["starter"])},
  "expires": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["udløber"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["land"])},
  "limit-per-customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["begrænsning pr. kunde"])},
  "limit-total-uses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["begræns det samlede antal anvendelser"])},
  "starts-at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["starter"])},
  "expires-at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["udløber"])},
  "specific-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["specifik bruger"])},
  "specific-organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["specifik organisation"])},
  "apply-to-courses-tagged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["anvend på kurser, der er tagget"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["navn"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dato"])},
  "venue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sted"])},
  "setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["indstilling"])},
  "first-club-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["første Brick Club-dato"])},
  "last-club-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sidste Brick Club-dato"])},
  "participants-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["antal deltagere"])},
  "places-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tilgængelige pladser"])},
  "session-notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sessionsnotater"])},
  "age-range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aldersgruppe"])},
  "session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["session"])},
  "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["notat"])},
  "initials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["initialer"])},
  "background-notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["baggrundsnotater"])},
  "brick-club-notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brick Club-notater"])},
  "created-at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oprettet"])},
  "course-trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udløs i slutningen af"])},
  "last-modified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sidst ændret"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["status"])},
  "facilitator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facilitator"])},
  "date-completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dato gennemført"])},
  "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugere"])},
  "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oprettet"])},
  "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["opdateret"])},
  "training-feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undervisningsfeedback"])},
  "submit-for-review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["indsend til gennemgang"])},
  "contact-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-mailadresse til brug ved kontakt"])},
  "contact-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kontakt os"])},
  "contact-trainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kontakt underviser"])},
  "save-brick-club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gem Brick Club"])},
  "edit-brick-club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rediger Brick Club"])},
  "mobile-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mobilnummer"])},
  "for-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Til"])},
  "survey-recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["modtager(e) af spørgeskema"])},
  "expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["udløb"])},
  "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["niveau"])},
  "payment-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["betalingstype"])},
  "auto-renew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["automatisk fornyelse"])},
  "auto-renew-explainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis automatisk fornyelse er slået TIL, bliver dit medlemsabonnement automatisk fornyet, når det udløber, ved hjælp af de kortoplysninger, der blev angivet på bookingtidspunktet. Slå denne indstilling FRA, hvis du ikke ønsker, at dit abonnement skal fornys. Du vil få tilsendt en e-mail med påmindelse, inden dit medlemskab udløber"])},
  "auto-renew-warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Forudsat at dit kort ikke er udløbet."])},
  "child-outcomes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["børneresultater"])},
  "club-trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udløs hver uge"])},
  "show-parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vis forælder"])},
  "discount-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rabatkode"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["send"])},
  "files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ﬁler"])},
  "upload-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["upload filer"])},
  "brick-club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brick Club"])},
  "move-this-child-to-new-club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flyt dette barn til ny Brick Club"])},
  "organisations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["organisationer"])},
  "first-name-initial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["initial – fornavn"])},
  "family-name-initial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["initial – efternavn"])},
  "middle-name-initial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["initial(er) – mellemnavn (valgfrit)"])},
  "parent-carer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["forælder/omsorgsperson"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-mail"])},
  "edit-club-participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger Brick Club-deltager"])},
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["velkommen"])},
  "primary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["primær"])},
  "additional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yderligere"])},
  "medication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["medicin"])},
  "thank-you-parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tak"])},
  "brick-club-members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brick Club-medlemmer"])},
  "view-member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis medlem"])},
  "Edit-Member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger medlem"])},
  "scheduled-events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planlagte events"])},
  "booked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["booket"])},
  "order-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordrenr."])},
  "placed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["afgivet"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["type"])},
  "website-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hjemmesidedata"])},
  "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordrer"])},
  "my-children": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mine børn"])},
  "my-child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit barn"])},
  "edit-child-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rediger oplysninger om barnet"])},
  "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tilføjet"])},
  "edit-child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger barn"])},
  "capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kapacitet"])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["beløb"])},
  "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mængde"])},
  "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bruger"])},
  "invoice-issued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faktura udstedt"])},
  "add-dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj datoer"])},
  "add-multiple-dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj flere datoer"])},
  "capacity-of-club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brick Clubs kapacitet"])},
  "add-new-club-member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj nyt Brick Club-medlem"])},
  "add-another": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tilføj et andet"])},
  "save-club-member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gem Brick Club-medlem"])},
  "edit club member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rediger Brick Club-medlem"])},
  "show-members-parent-carer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vis medlems forælder/omsorgsperson"])},
  "brick-club-note-for-member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brick Club-notat om medlem"])},
  "visible-to-member-parent-carer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan ses af medlems forælder/omsorgsperson"])},
  "my-brick-clubs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mine Brick Clubs"])},
  "manage-brick-club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrer Brick Club"])},
  "add-note-for-member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj notat om medlem"])},
  "visible-to-members-parent-carer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan ses af medlems forælder/omsorgsperson"])},
  "facilitator-session-feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tjekliste og notater for session"])},
  "please-select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg"])},
  "participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["deltager"])},
  "save-survey-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gem-spørgeskemaoplysninger"])},
  "no-responses-to-this-survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen svar på dette spørgeskema"])},
  "view-sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis sessioner"])},
  "view-progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis fremskridt"])},
  "club-delete-confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dermed slettes alle datoer, sessionsnotater og elevnotater, der er forbundet med denne Brick Club"])},
  "this-club-is-private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne Brick Club er privat"])},
  "this-club-is-public-and-not-accepting-bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg denne indstilling, hvis du ikke ønsker at modtage bookingforespørgsler"])},
  "this-club-is-public-and-accepting-bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette vil vise din e-mail og din hjemmeside"])},
  "please-select-visibility-level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg niveau for synlighed"])},
  "do-not-list-on-directory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis ikke i Brick Club-fortegnelsen"])},
  "list-on-directory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis i Brick Club-fortegnelsen"])},
  "list-on-directory-as-private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis i Brick Club-fortegnelsen som privat Brick Club"])},
  "smartsurvey-feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["session-tjekliste-spørgeskema-advarsel"])},
  "remove-participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern deltager"])},
  "move-club-member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flyt medlem til en anden Brick Club"])},
  "date-added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dato tilføjet"])},
  "add-a-new-discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj en ny rabat"])},
  "verifone-product-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifone-produktkode"])},
  "merchant-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forhandlerens navn"])},
  "percent-off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rabatprocent"])},
  "edit-discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rediger rabat"])},
  "license-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licensoplysninger"])},
  "issued by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udstedt af"])},
  "attendee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["deltager"])},
  "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["event"])},
  "subscription-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnementsoplysninger"])},
  "delegates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delegerede"])},
  "invoice-paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["faktura betalt"])},
  "payment-gateway-customer-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalings-gateway – kunde-id"])},
  "payment-gateway-order-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalings-gateway – ordre-id"])},
  "event-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eventdato"])},
  "club-attendance-help-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her kan du spore Brick Club-medlemmers deltagelse/fremmøde i Brick Club"])},
  "total-no-of-sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal sessioner i alt"])},
  "total-percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samlet %"])},
  "group-notes-for-session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppenotater for session"])},
  "session-checklist-scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorer for sessionstjekliste"])},
  "view-childs-progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis barns fremskridt"])},
  "secondary-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sekundær e-mail"])},
  "secondary-phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sekundært telefonnummer"])},
  "event-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["event-id"])},
  "get-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Få hjælp"])},
  "your-trainers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dine undervisere"])},
  "medication-question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Har eller tager dit barn medicin, som facilitatoren bør kende til?"])},
  "additional-question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er der andet, som facilitatoren bør vide om dit barn for at hjælpe barnet med at få en positiv oplevelse i Brick Club?"])},
  "progress-and-feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fremskridt og feedback"])},
  "member-session-feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessionsfeedback fra medlem"])},
  "goal-tracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Målsporing"])},
  "goal-tracking-helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her kan du se og spore de mål, dit barn arbejder for at nå i Brick Club"])},
  "current-goal-tracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sporing af aktuelt mål"])},
  "programme-feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programfeedback"])},
  "member-session-feedback-helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her kan du gennemgå feedback fra dit barn i barnets Brick Club."])},
  "view-feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis feedback"])},
  "view-outcomes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis resultater"])},
  "club-members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brick Club-medlemmer"])},
  "club-members-helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her kan du administrere dine Brick Club-medlemmer og få vist deres profiler"])},
  "session-checklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessionstjekliste"])},
  "member-attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medlems deltagelse/fremmøde"])},
  "member-progress-survey-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det er tid til at evaluere Brick Club."])},
  "session-checklist-survey-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessionstjeklisten kan hjælpe dig med at holde øje med, hvor tro du er mod Brick-by-Brick®-metoden"])},
  "default-brick-club-survey-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvad syntes Brick Club-medlemmerne om dagens session?"])},
  "group-notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppenotater"])},
  "back-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbage til"])},
  "member-outcomes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medlemsresultater"])},
  "email-helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette er den e-mailadresse, du skal bruge til at logge ind på din Play Included-konto, og hvor du vil modtage e-mails fra Play Included"])},
  "show-facilitator-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis facilitatorprofil"])},
  "show-trainer-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis underviserprofil"])},
  "your-profile-is-currently-visible-trainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din profil er på nuværende tidspunkt synlig på hjemmesiden som en underviser."])},
  "your-profile-is-currently-visible-facilitator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din profil er på nuværende tidspunkt synlig på hjemmesiden som en facilitator."])},
  "add-a-member-note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj et medlemsnotat"])},
  "add-member-session-feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj sessionsfeedback fra medlem"])},
  "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mål"])},
  "add-a-goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj et mål"])},
  "date-you-set-this-goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dato, hvor du fastsatte dette mål"])},
  "category-of-goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Målkategori"])},
  "add-a-member-goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj et deltagermål"])},
  "archive-goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkivér mål"])},
  "completion-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gennemførelsesdato"])},
  "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notater"])},
  "achieved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nået"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nej"])},
  "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkivér"])},
  "add-progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj fremskridt"])},
  "goal-progress-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I dag vil vi vurdere Brick Club-medlemmernes fremskridt i forhold til at nå dette mål som:"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["luk"])},
  "goal-notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Målnotater"])},
  "group-goal-tracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sporing af gruppemål"])},
  "add-goal-helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her kan du opdatere fremskridt i forhold til at nå dette mål."])},
  "are-you-sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er du sikker?"])},
  "goal-tracking-alert-helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj maksimalt tre mål for at sikre, at målene giver mening"])},
  "goal-tracking-helptext-facilitator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her kan du gennemgå fremskridt i forhold til at nå hvert mål i Brick Club."])},
  "no-active-goals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der er ingen aktive mål"])},
  "start-score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startscore"])},
  "end-goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slutscore"])},
  "steps-travelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbagelagte trin"])},
  "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessioner"])},
  "no-archived-goals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der er ingen arkiverede mål"])},
  "member-session-feedback-helptext-facilitator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her kan du tilføje og gennemgå sessionsfeedback fra Brick Club-medlemmet. Det vil blive delt med Brick Club-medlemmets familie. Du kan se og gennemgå tilladelserne for deling her."])},
  "arrow-keys-helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brug pilene til at se flere datoer"])},
  "date-goal-set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dato, hvor dette mål blev fastsat"])},
  "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori"])},
  "child-notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notater om barn"])},
  "child-session-feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessionsfeedback fra barn"])},
  "progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fremskridt"])},
  "archived-goals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkiverede mål"])},
  "child-views": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Børnevisninger"])},
  "graph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graf"])},
  "table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabel"])},
  "parent-views": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forældrevisninger"])},
  "facilitator-views": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facilitatorvisninger"])},
  "renewal-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dato for fornyelse"])},
  "auto-renewal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisk fornyelse"])},
  "no-smartsurvey-access-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har i øjeblikket ikke adgang til denne funktion."])},
  "member-notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medlemsnotater"])},
  "member-views": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medlemsvisninger"])},
  "member-progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medlemsfremskridt"])},
  "club-name-helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette navn er kun beregnet som din reference"])},
  "club-address-helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette vil blive vist i Brick Club-fortegnelsen, hvis din Brick Clubs synlighed er indstillet til Offentlig"])},
  "view-brick-club-on-website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis i Brick Club-fortegnelse"])},
  "club-email-helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette er den e-mailadresse, der vil blive vist i Register over fagfolk og Brick Club-fortegnelsen, hvis du har indstillet din profil og dine Brick Clubs til at være synlige"])},
  "host-country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Værts land"])},
  "what-does-this-mean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvad betyder det?"])},
  "end-goal-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slutmål"])},
  "files-download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filer (klik for at downloade)"])},
  "parent-only-field-helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oplysninger, som forælderen/omsorgspersonen ønsker, at du skal have, for at støtte medlemmet, så medlemmet får en positiv oplevelse i Brick Club."])},
  "first-delivery-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Første leveringsdato"])},
  "event-tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anvend på events med tag (valgfrit)"])},
  "usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brug"])},
  "brick-club-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navn på Brick Club"])},
  "publish-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dato for offentliggørelse"])},
  "onboarding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onboarding"])},
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg"])},
  "parent-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg denne indstilling, hvis du er forælder/omsorgsperson, der gerne vil have adgang til ressourcer for forældre/omsorgspersoner"])},
  "professional-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg dette, hvis du er en fagperson, der gerne vil have adgang til ressourcer for fagfolk og eventuelt tilmelde dig kurser for fagfolk."])},
  "parent-no-smartsurvey-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne funktion er endnu ikke tilgængelig for dit barns Brick Club. Du får besked pr. e-mail, hvis denne funktion bliver tilgængelig"])},
  "facilitator-no-smartsurvey-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne beta-funktion er ikke tilgængelig for din abonnementstype på nuværende tidspunkt. Vi sender en e-mail, når den bliver tilgængelig."])},
  "trainer-no-smartsurvey-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne beta-funktion er ikke tilgængelig for din abonnementstype på nuværende tidspunkt. Vi sender en e-mail, når den bliver tilgængelig."])},
  "facilitator-evaluation-nudge-banner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvordan var Brick Club-oplevelsen? Du bedes udfylde spørgeskemaet med feedback, når din Brick Club stopper"])},
  "complete-survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["udfyld spørgeskema"])},
  "view-session-attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis deltagelse/fremmøde i sessioner og sessionsnotater"])},
  "add-presenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj oplægsholder"])},
  "no-presenters-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen tilgængelige oplægsholdere"])},
  "brick-club-schedule-helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du skal tilføje alle datoer i forbindelse med din Brick Club. Når du opretter den, skal du tilføje mindst 1 dato, hvorefter du kan tilføje flere datoer ved at redigere en Brick Club"])},
  "no-places-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ingen tilgængelige pladser"])},
  "place-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tilgængelig plads"])},
  "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["roller"])},
  "affiliate-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partner-id"])},
  "funds-recieved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beløb modtaget"])},
  "view-all-clubs-length-brick-clubs": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Vis alle ", _interpolate(_list(0)), " Brick Clubs"])},
  "view-all-facilitators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis alle facilitatorer"])},
  "facilitators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facilitatorer"])},
  "please-select-a-brick-club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg en Brick Club"])},
  "no-brick-clubs-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der er ingen tilgængelige Brick Clubs"])},
  "attendance-change-warning-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bemærk, at hvis du ændrer datoen her, ændres den planlagte dato for din Brick Club også. Du bør kun ændre den, hvis datoen for Brick Club-sessionen er flyttet"])},
  "record-member-feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrer medlems sessionsfeedback og -mål"])},
  "select-country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg land"])},
  "save-selected-country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gem valgt land"])},
  "tax-free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Momsfri"])},
  "total-meta-pagination-total_entries-items": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["I alt ", _interpolate(_list(0)), " elementer"])},
  "manage-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrer profil"])},
  "public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["offentlig"])},
  "private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["privat"])},
  "viewable-on-website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(kan ses på hjemmesiden)"])},
  "not-viewable-on-website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(kan ikke ses på hjemmesiden)"])},
  "invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["faktura"])},
  "not-paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ubetalt"])},
  "tax-registration-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CVR-nummer"])},
  "purchase-order-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indkøbsordrenummer"])},
  "not-received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ikke modtaget"])},
  "payment-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalingsbeløb"])},
  "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["moms"])},
  "redeemed-license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indløst licens"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["søg"])},
  "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aktiv"])},
  "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["afventer"])},
  "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["udløbet"])},
  "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ingen"])},
  "manual-subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manuelt abonnement"])},
  "subscribe-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret abonnement for bruger"])},
  "view-all-orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis alle ordrer"])},
  "this-week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne uge"])},
  "this-month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne måned"])},
  "last-week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sidste uge"])},
  "view-all-courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis alle kurser"])},
  "add-a-parent-carer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj en forælder/omsorgsperson"])},
  "upload-resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload ressource"])},
  "add-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj event"])},
  "there-was-an-error-fetching-the-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der opstod en fejl under hentning af eventen"])},
  "there-was-an-error-updating-this-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der opstod en fejl under opdatering af denne event"])},
  "event-updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eventen blev opdateret"])},
  "there-was-an-error-updating-event-visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der opstod en fejl under opdatering af eventens synlighed"])},
  "event-visibility-updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eventens synlighed blev opdateret"])},
  "show-on-google-maps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis på Google Maps"])},
  "event-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eventoplysninger"])},
  "this-will-cancel-the-event-and-notify-all-registered-attendees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dermed aflyses eventen, og alle registrerede deltagere får besked om aflysningen."])},
  "unlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubegrænset"])},
  "confirm-event-cancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekræft aflysning af event"])},
  "error-updating-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der opstod en fejl under opdatering af eventen"])},
  "i-e-paypal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dvs. PayPal"])},
  "includes-tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inkl. moms?"])},
  "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valuta"])},
  "please-add-a-direct-link-to-help-the-user-find-the-venue-e-g": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj et direkte link for at hjælpe brugeren med at finde stedet, f.eks."])},
  "google-maps-optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google Maps (valgfrit)"])},
  "please-select-a-country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg et land"])},
  "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postnummer"])},
  "address-line-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresselinje 2"])},
  "address-line-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresselinje 1"])},
  "selectedtemplate-type-e-learning-e-learning-online-meeting-link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "-link"])},
  "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valgfrit"])},
  "event-summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oversigt over event"])},
  "you-are-editing-a-event-status-event-any-changes-will-effect-this-event": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Du redigerer en ", _interpolate(_list(0)), "-event. Eventuelle ændringer vil påvirke denne event."])},
  "error-creating-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der opstod en fejl under oprettelse af eventen"])},
  "event-created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eventen blev oprettet"])},
  "please-select-a-country-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg et land"])},
  "submit-training-event-for-review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indsend undervisningsevent til gennemgang"])},
  "save-as-draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gem som kladde"])},
  "selectedtemplate-delivery-e-learning-e-learning-online-meeting-link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "-link"])},
  "all-times-are-in-short_timezone": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Alle tidspunkter er angivet i ", _interpolate(_list(0))])},
  "for-more-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for at få yderligere oplysninger."])},
  "play-included": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play Included"])},
  "there-are-currently-no-templates-available-to-you-please-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har ingen tilgængelige skabeloner på nuværende tidspunkt. Kontakt"])},
  "use-this-template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brug denne skabelon"])},
  "to-get-started-please-select-a-course-template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg en kursusskabelon for at komme i gang"])},
  "introductory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduktion"])},
  "continuing-professional-development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Løbende faglig udvikling"])},
  "drop-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onlinemøde"])},
  "Training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undervisning"])},
  "add-course-template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj kursusskabelon"])},
  "view-template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis skabelon"])},
  "key-programme-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vigtige programoplysninger"])},
  "moodle-course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moodle-kursus"])},
  "registration-rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registreringsregler og -metode"])},
  "please-add-atleast-1-rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj mindst 1 registreringsregel."])},
  "edit-registration-rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger registreringsregler"])},
  "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prissætning"])},
  "please-add-atleast-one-country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj mindst 1 land og pris"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rediger"])},
  "add-a-country-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj en ny landepris"])},
  "countries-and-pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lande og prissætning"])},
  "subscription-level-on-completion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnementsniveau ved fuldførelse"])},
  "merchant-product-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forhandlerproduktkode"])},
  "merchant-product-code-helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis du indtaster en Verifone-produktkode, anvendes priserne i tabellen Lande og prissætning ikke. I stedet bruges priserne fra 2Checkout."])},
  "merchant-product-code-warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BEMÆRK: Du skal stadig angive oplysninger vedr. nedenstående lande for at sikre, at produktet vises på hjemmesiden."])},
  "save-course-template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gem kursusskabelon"])},
  "select-course-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg en kursusbeskrivelse"])},
  "view-course-in-cms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis kursusbeskrivelser i CMS"])},
  "storyblok-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storyblok-beskrivelse"])},
  "template-created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skabelonen blev oprettet"])},
  "error-creating-template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der opstod en fejl under oprettelse af skabelonen"])},
  "template-updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skabelonen blev opdateret"])},
  "error-updating-template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der opstod en fejl under opdatering af skabelonen"])},
  "new-course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyt kursus"])},
  "country-and-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land og pris"])},
  "excluding-vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekskl. moms"])},
  "select-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg alt"])},
  "user-must-be": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugeren skal være"])},
  "add-registration-rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj registreringsregler"])},
  "latest-course-templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seneste kursusskabeloner"])},
  "see-all-course-templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se alle kursusskabeloner"])},
  "upcoming-training-events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommende undervisningsevents"])},
  "see-all-training-events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se alle undervisningsevents"])},
  "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online"])},
  "no-date-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen tilgængelig dato"])},
  "event-invoice-marked-as-issued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eventfakturaen markeret som udstedt"])},
  "event-invoice-marked-as-paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eventfakturaen markeret som betalt"])},
  "discount-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rabatoplysninger"])},
  "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktiv"])},
  "delete-discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet rabat"])},
  "error-deleting-discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der opstod en fejl under sletning af rabatten"])},
  "discount-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rabatten blev slettet"])},
  "add-a-discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj en rabat"])},
  "discount-used": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0))])},
  "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tags"])},
  "enter-new-tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angiv nye tags adskilt af komma"])},
  "tag-name-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navn på tag skal angives"])},
  "tag-created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagget blev oprettet"])},
  "error-creating-tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der opstod en fejl under oprettelse af tagget"])},
  "current-subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuværende abonnement"])},
  "subscription-updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnementet blev opdateret"])},
  "error-updating-subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der opstod en fejl under opdatering af abonnementet"])},
  "user-subscribed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement oprettet for bruger"])},
  "error-subscribing-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der opstod en fejl under oprettelse af abonnementet for brugeren"])},
  "include-tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inkl. moms"])},
  "exclude-tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekskl. moms"])},
  "course-prerequisites-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forudsætninger for kursus"])},
  "to-attend-the-booking-event-name-course-please-accept-our-mandatory-course-prerequisites": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["For at deltage i ", _interpolate(_list(0)), "-kursus skal du acceptere vores obligatoriske forudsætninger for kurset."])},
  "view-club-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis Brick Club-oplysninger"])},
  "this-user-has-no-brick-clubs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne bruger har endnu ikke stået for nogen Brick Clubs"])},
  "course-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursusoplysninger"])},
  "completed-event-date": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Gennemført ", _interpolate(_list(0))])},
  "in-progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I gang"])},
  "view-certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis certifikat"])},
  "this-user-has-no-events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne bruger har endnu ikke tilmeldt sig nogen undervisningsevents"])},
  "you-have-licenses-available": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Du har ", _interpolate(_list(0)), " pladser, der kan indløses, og ", _interpolate(_list(1)), " afventende pladser for denne type event"])},
  "add-user-to-course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Føj bruger til kursus"])},
  "organisation-has-licenses-available": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Denne organisation har ", _interpolate(_list(0)), " pladser, der kan indløses, og ", _interpolate(_list(1)), " afventende pladser for denne type event"])},
  "active-courses-by-play-included": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktive kurser fra Play Included"])},
  "your-facilitators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dine facilitatorer"])},
  "view-courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis kurser"])},
  "courses-cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vidste du, at Play Included har en række ekstra kurser til fortsat faglig udvikling (CPD)?"])},
  "your-brick-clubs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dine Brick Clubs"])},
  "view-all-clubs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis alle Brick Clubs"])},
  "continue-events-cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortsæt din Brick-by-Brick®-rejse ved at booke deltagelse for dig selv i Brick-by-Brick-programmet: Facilitatorkursus i dag."])},
  "update-subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdater abonnement"])},
  "subscription-expires": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Abonnementet udløber – ", _interpolate(_list(0))])},
  "subscription-expired": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Abonnementet udløb – ", _interpolate(_list(0))])},
  "current-membership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuværende medlemskab"])},
  "view-all-trainers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis alle undervisere"])},
  "view-all-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis alle brugere"])},
  "copy-event-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiér link til event"])},
  "trainer-pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prissætning for undervisere"])},
  "club-note-member-note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her kan du lave notater om en Brick Club-session. Det er kun dig, der kan se disse notater. Du kan skrive notater om individuelle medlemmer og vælge at dele dem med forældre/omsorgspersoner på fanen Medlemmer."])},
  "start-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startdato"])},
  "end-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slutdato"])},
  "export-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksportér data"])},
  "create-manual-booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret manuel booking"])},
  "notes-only-visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notater kan kun ses af dig og eventuelt medlemmets forælder eller omsorgsperson"])},
  "view-resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis ressource"])},
  "view-resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis ressourcer"])},
  "no-resources": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Der er på nuværende tidspunkt ingen ", _interpolate(_list(0)), "-ressourcer"])},
  "role-resources": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "-ressourcer"])},
  "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flyt"])},
  "manage-license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrer licens"])},
  "credit-debit-card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kredit-/debetkort"])},
  "single-booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enkelt booking"])},
  "group-booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppebooking"])},
  "answer-choices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svarmuligheder"])},
  "responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svar"])},
  "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe"])},
  "subscription-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnementsstatus"])},
  "no-organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen organisation"])},
  "manual-add-user-to-course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Føj bruger til kursus manuelt"])},
  "user-is-ineligible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugeren kan ikke vælges"])},
  "discount-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rabatbeløb"])},
  "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i alt"])},
  "select-marketing-preferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg marketingpræferencer"])},
  "marketing-preferences-copy-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velkommen til din Play Included-portal! Vi er glade for, at du har lyst til at være med. Før du kigger dig omkring, bedes du bruge et øjeblik på at dele dine kommunikationspræferencer med os. Vi vil meget gerne kunne holde dig opdateret om spændende nyheder, tips og ressourcer. Du modtager automatisk e-mails om dit medlemskab eller dine køb, men for at kunne modtage yderligere e-mails bedes du give dit samtykke nedenfor."])},
  "i-would-like-to-receive-emails-from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg vil gerne modtage e-mails fra Play Included om:"])},
  "marketing-preferences-copy-2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Vi tager fortrolighed alvorligt, og dine data vil blive behandlet med stor omhu i overensstemmelse med vores ", _interpolate(_list(0)), ". Du kan altid ændre dine præferencer ved at gå til \"Min profil\" i portalen."])},
  "privacy-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortrolighedspolitik"])},
  "requires-a-subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kræver et abonnement?"])},
  "subscription-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnementsbeløb"])},
  "tax-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Momsbeløb"])},
  "total-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beløb i alt"])},
  "renewal-only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kun fornyelse"])},
  "renewals-only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kun fornyelser"])},
  "affiliate_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partner-id"])},
  "template_field_disabled": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Nogle felter er blevet deaktiveret, da dette kursus har ", _interpolate(_list(0)), " events i systemet. Hvis du har brug for at ændre nogen af de deaktiverede felter, skal du oprette en ny skabelon og event"])},
  "no-change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen ændring"])},
  "payment-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalingsstatus"])},
  "discount-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rabat-id"])},
  "discount-percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rabatprocent"])},
  "discounted-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beløb med rabat"])},
  "pretax-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beløb før moms"])},
  "please-enter-a-payment-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angiv et betalingslink"])},
  "please-enter-a-payment-gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angiv en betalings-gateway"])},
  "licenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["licenser"])},
  "subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["abonnement"])},
  "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["abonnement"])},
  "One-off fee Product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt med engangsgebyr"])},
  "Bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bookinger"])},
  "add-new-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj ny bruger"])},
  "license-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licens"])},
  "added-by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføjet af"])},
  "redeem-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indløs plads"])},
  "first-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Første dato"])},
  "group-booking-financial-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Økonomiske oplysninger om gruppebooking"])},
  "places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pladser"])},
  "net-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nettopris"])},
  "delegate-within-own-organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delegeret i min organisation"])},
  "bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bookinger"])},
  "transaction-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktions-id"])},
  "order-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordredato"])},
  "refunded?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refunderet?"])},
  "refunded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refunderet"])},
  "back-to-website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbage til vores hjemmeside"])},
  "all-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hele tiden"])},
  "sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["salg"])},
  "abandoned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afbrudt"])},
  "select-your-language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg dit sprog"])},
  "step-of-four": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["trin ", _interpolate(_list(0)), " af 4"])},
  "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilladelser"])},
  "parent-onboard-permissions-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play Included-portalen er det sted, hvor vi administrerer og overvåger Brick Clubs. Hos Play Included bestræber vi os på at levere Brick Club-oplevelser af høj kvalitet til medlemmerne, og vi mener, at det er vigtigt at give dig værktøjer til at administrere og overvåge Brick Clubs for at nå dette mål. Du kan læse mere om, hvordan vi håndterer data vedrørende Brick Club-medlemmer og Brick Clubs her"])},
  "parent-onboarding-attendance-permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg giver tilladelse til, at Brick Club-facilitatoren må bruge Play Included-portalen til at registrere mit barns deltagelse/fremmøde og tilføje sessionsnotater om mit barn. Disse data vil kunne ses af Brick Club-facilitatoren, dig som medlemmets familie, eventuelle Brick Club-administratorer og Play Included."])},
  "parent-onboarding-survey-permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg giver tilladelse til, at facilitatoren må registrere mit barns fremskridt i Smart Survey og på Play Included-portalen. Disse oplysninger vil blive delt med dig (og eventuelle yderligere forældre/omsorgspersoner, hvor det er relevant) på Play Included-portalen. Dataene vil også kunne ses af Brick Club-administratoren og Play Included."])},
  "parent-onboarding-no-permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg giver ikke samtykke til, at facilitatoren må registrere deltagelse/fremmøde eller fremskridt i Brick Clubs. Jeg forstår, at mit barns deltagelse/fremmøde eller fremskridt i Brick Clubs ikke vil blive registreret."])},
  "parent-onboarding-aware-of-participation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis det er relevant, er mit barn klar over, at barnet deltager i Brick-by-Brick®-programmet og er blevet føjet til Play Included-portalen. Hvis dit barn ikke ønsker at deltage, bedes du kontakte Brick Club-facilitatoren. Du og dit barn har mulighed for at vælge, hvilke data der deles med Brick Club-facilitatorer, administratorer og Play Included, og du kan administrere disse tilladelser i sektionen \"Mine børn\" på din portal under \"Administrer profil"])},
  "parent-onboarding-aware-check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["her."])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["næste"])},
  "parent-onboarding-started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lad os komme i gang."])},
  "parent-onboarding-started-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For at sikre, at vi leverer den bedst mulige Brick Club-oplevelse, vil vi gerne have bekræftet nogle oplysninger fra dig om dit barn."])},
  "parent-onboarding-started-text-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inkludering af disse oplysninger betyder, at dit barns initialer vil blive brugt generelt på portalen, hvilket giver dig og dit barn en mere personlig og individuel oplevelse. Du kan altid ændre oplysningerne i dit barns profil på portalen."])},
  "parent-onboarding-started-text-3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Du kan læse om, hvordan Play Included administrerer personlige data ", _interpolate(_list(0))])},
  "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["her."])},
  "child-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oplysninger om barnet"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tilbage"])},
  "parent-carer-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oplysninger om forælder/omsorgsperson"])},
  "parent-carer-details-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herefter vil vi indsamle dine oplysninger. De vil kun blive brugt til at kontakte dig om Brick Club og eventuel opdatering af dit barns data i vores system. Du vil også få tilsendt opdateringer via e-mail. Du kan redigere dine kommunikationsindstillinger under \"Min profil\"."])},
  "parent-carer-details-text-3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du kan altid opdatere, redigere eller ændre dine kontaktoplysninger under ", _interpolate(_named("strong")), "."])},
  "parent-carer-details-text-2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Der er tilgængelige oplysninger om, hvordan Play Included gemmer og administrerer kontaktoplysninger i vores ", _interpolate(_list(0))])},
  "member-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medlemsprofil"])},
  "member-profile-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vi ønsker, at Brick Clubs skal være sjove, trygge rum for medlemmerne. For vores facilitatorer kan lidt ekstra oplysninger om medlemmerne, før de kommer til Brick Club, hjælpe dem med at skabe det ønskede trygge miljø og sikre, at medlemmerne føler sig godt tilpas."])},
  "member-profile-text-2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Disse oplysninger vil kun blive delt med Brick Club-facilitatoren og facilitatorens organisation (hvis det er relevant) og bør kun omfatte oplysninger, der er nødvendige for at sikre problemfri og sikker drift af den pågældende Brick Club. Play Included har ikke adgang til disse data. Du kan læse om, hvordan Play Included administrerer data i vores ", _interpolate(_list(0))])},
  "medication-warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sørg for ikke at tilføje data, der kan bruges til at identificere dig eller dit barn, her (f.eks. adresse, barnets fulde navn eller fødselsdato)"])},
  "make-primary-parent-carer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gør til primær forælder/omsorgsperson"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nulstil"])},
  "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skift adgangskode"])},
  "change-password-warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du bliver sendt til vores enkeltlogin-system (Single Sign On-system – SSO), hvis du vil skifte adgangskode."])},
  "all-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle roller"])},
  "resources-pagination": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["I alt ", _interpolate(_list(0)), " elementer"])},
  "create-brick-club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj ny Brick Club"])},
  "show-all-events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis kun events i min organisation"])},
  "show-my-events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis events, hvor jeg har booket deltagelse for delegerede"])},
  "view-my-delegates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis mine delegerede"])},
  "unauthorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uautoriseret adgang"])},
  "annual-subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Årsabonnementer"])},
  "view-subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis abonnement"])},
  "background-notes-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disse oplysninger er blevet tilføjet af barnets forælder/omsorgsperson og kan ikke redigeres af facilitatorer."])},
  "member-medication-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tager medlemmet nogen form for medicin?"])},
  "parent-carer-consent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtykke fra forælder/omsorgsperson"])},
  "parent-carer-survey-permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forælder/omsorgsperson har givet samtykke til, at deltagelse/fremmøde og sessionsnotater må registreres af facilitatoren på Play Included-portalen"])},
  "parent-carer-progress-permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forælder/omsorgsperson har givet samtykke til, at fremskridt må registreres af facilitatoren på Play Included-portalen"])},
  "parent-carer-attendance-notes-permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forælder/omsorgsperson har givet samtykke til, at deltagelse/fremmøde og sessionsnotater må registreres af facilitatoren på Play Included-portalen"])},
  "parent-carer-no-attendance-notes-permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forælder/omsorgsperson har ikke givet samtykke til, at deltagelse/fremmøde og sessionsnotater må registreres af facilitatoren på Play Included-portalen"])},
  "member-background-info-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disse oplysninger er blevet tilføjet af barnets forælder/omsorgsperson og kan ikke redigeres af facilitatorer."])},
  "member-medication-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tager medlemmet nogen form for medicin?"])},
  "member-additional-notes-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oplysninger, som forælderen/omsorgspersonen ønsker, at du skal have, for at støtte medlemmet, så medlemmet får en positiv oplevelse i Brick Club."])},
  "parent-carer-subheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtykke fra forælder/omsorgsperson"])},
  "parent-attendance-session-notes-consent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Forælder/omsorgsperson ", _interpolate(_list(0)), " har givet samtykke til, at deltagelse/fremmøde og sessionsnotater må registreres af facilitatoren på Play Included-portalen"])},
  "parent-survey-consent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Forælder/omsorgsperson ", _interpolate(_list(0)), " har givet samtykke til, at fremskridt må registreres af facilitatoren på Play Included-portalen"])},
  "parent-no-consent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forælder/omsorgsperson har ikke givet samtykke til, at deltagelse/fremmøde og sessionsnotater må registreres af facilitatoren på Play Included-portalen"])},
  "your-bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dine bookinger"])},
  "user_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugergruppe"])},
  "training_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undervisningsevent"])},
  "training_events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undervisningsevents"])},
  "user_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugergrupper"])},
  "add_a_new_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj en ny event"])},
  "brick_clubs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brick Clubs"])},
  "all-types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle typer"])},
  "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitet"])},
  "facilitation-aid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faciliteringsressourcer"])},
  "club-promotion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Støtte- og marketingmateriale"])},
  "supporting-asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brick Club-ressourcer"])},
  "training-resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undervisningsressourcer"])},
  "all-tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle tags"])},
  "all-languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle sprog"])},
  "cpd-for-certified-facilitators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurser til fortsat faglig udvikling (CPD) for facilitatorer"])},
  "add_to_moodle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Føj til Moodle"])},
  "create-account-in-moodle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret konto i Moodle"])},
  "max-attendees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maks. antal deltagere"])},
  "attendees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deltagere"])},
  "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjernet"])},
  "booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking"])},
  "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordre"])},
  "order-on-website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordre på hjemmeside"])},
  "access-training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Få adgang til undervisning"])},
  "training-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undervisningsstatus"])},
  "certified-facilitator-course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificeret facilitator-kursus"])},
  "advanced-facilitator-course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avanceret facilitatorkursus"])},
  "product-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktkode"])},
  "add-a-new-organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj en ny organisation"])},
  "add-new-organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj ny organisation"])},
  "organisation-ran-by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blev drevet af"])},
  "upcoming-courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommende kurser"])},
  "not-started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke startet"])},
  "product_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktkode"])},
  "discounted_product_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kode til produktrabat"])},
  "discount_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rabatkode"])},
  "subscriptions_purchased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Købte abonnementer"])},
  "turn-off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisk fornyelse er slået TIL. Klik her for at slå indstillingen fra"])},
  "turn-on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisk fornyelse er slået FRA. Klik her for at slå indstillingen til"])},
  "directory-website-helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette er den URL, der vil blive vist i Register over fagfolk og Brick Club-fortegnelsen, hvis du har indstillet din profil og dine Brick Clubs til at være synlige"])},
  "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stat"])},
  "county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amt"])},
  "view_certificate_pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se certifikat (PDF)"])},
  "course-blocked-pending-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tjek venligst, at betaling er foretaget, og forudsætninger er blevet accepteret"])},
  "my-subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mine abonnementer"])},
  "play-included-mailing-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spil inkluderet postliste"])},
  "certified-facilitators-trainees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificerede facilitatorelever"])},
  "advanced-facilitators-trainees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avancerede facilitatorelever"])},
  "new-products-and-development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nye produkter og udvikling"])},
  "play-included-news-and-updates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spil Inkluderede nyheder og opdateringer"])},
  "training-courses-and-cpd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Træningskurser og CPD"])},
  "trainers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trænere"])},
  "advanced-facilitators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avancerede facilitatorer"])},
  "training-partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Træningspartnere"])},
  "parent-carers-of-brick-club-members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forældre/plejere til Brick Club-medlemmer"])},
  "parentcarer-news-and-updates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forældre/plejer nyheder og opdateringer"])},
  "certified-facilitators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificerede facilitatorer"])},
  "play-at-home-member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spil hjemme-medlem"])},
  "resource-card-header": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " Ressource"])},
  "parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spil hjemme-medlem"])},
  "paid-professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brick Club Basics"])},
  "certified-facilitator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificeret facilitator"])},
  "certified-facilitator-trainee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificeret Facilitator Trainee"])},
  "professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spil Inkluderet Introduktionsmedlem"])},
  "brick-club-family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brick Club Familie"])},
  "advanced-facilitator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avanceret facilitator"])},
  "advanced-facilitator-trainee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced Facilitator Trainee"])},
  "l3-trainee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau 03 Trainee"])},
  "l4a-trainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Træner"])},
  "l4b-trainee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau 04b Trainee"])},
  "l3-specialist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau 03 Specialist"])},
  "l4a-trainee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau 04a Trainee"])},
  "l4b-mentor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau 04b Mentor"])},
  "facilitator-organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brick-by-Brick® Group Admin"])},
  "courses-admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurser Admin"])},
  "users-admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruger Admin"])},
  "brick-club-admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brick Clubs Admin"])},
  "trainer-organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Træner organisation"])},
  "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
  "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["engelsk"])},
  "chinese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kinesisk"])},
  "chinese-hong-kong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kinesisk (Hongkong)"])},
  "danish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dansk"])},
  "spanish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["spansk"])}
}