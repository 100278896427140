export const worksWithOptions = [
  "Early Years setting (children under 4 years)",
  "School (children 4-11 years)",
  "Secondary School (children 11-16 years)",
  "Further Education (16+ years)",
  "Adult service (18+ years)",
  "Older adult service (65+ years)",
  "Specialist Educational provision",
  "State funded healthcare setting (e.g NHS)",
  "Private healthcare setting (e.g private therapy centre)",
  "other-1",
  "other-2",
];
