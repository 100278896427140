export function deserializeSlug(slug: string): string {
  if (!slug) {
    return "";
  }

  return slug.replace(/-/g, " ");
}

export function slugify(text: string): string {
  return text
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");
}
