import TemplatesIndex from "@/views/templates/TemplatesIndex.vue";
import TemplatesList from "@/views/templates/TemplatesList.vue";
import TemplatePage from "@/views/templates/TemplatePage.vue";
import NewTemplate from "@/views/templates/NewTemplate.vue";
import EditTemplatePage from "@/views/templates/EditTemplatePage.vue";

import EventsList from "@/views/events/EventsList.vue";
import EventPage from "@/views/events/EventPage.vue";
import NewEvent from "@/views/events/NewEvent.vue";
import EditEventPage from "@/views/events/EditEventPage.vue";
import OrderPage from "@/views/orders/OrderPage.vue";

import CertificatesList from "@/views/CertificatesList.vue";

export const courseRoutes = [
  {
    path: "/dashboard/courses",
    name: "Courses",
    component: TemplatesIndex,
    meta: {
      name: "Courses",
      breadcrumbs: ["Dashboard", "Courses"],
    },
  },
  {
    path: "/dashboard/courses/certificates",
    name: "Certificates",

    component: CertificatesList,
    meta: {
      name: "Certificates",
      breadcrumbs: ["Dashboard", "Courses", "Certificates"],
    },
  },
  {
    path: "/dashboard/courses/templates",
    name: "Course-Templates",

    component: TemplatesList,
    meta: {
      name: "Course Templates",
      breadcrumbs: ["Dashboard", "Courses", "Course-Templates"],
    },
  },
  {
    path: "/dashboard/courses/templates/new",
    name: "Add_a_new_Template",

    component: NewTemplate,
    meta: {
      name: "Add a new Template",
      breadcrumbs: ["Dashboard", "Courses", "Course_Templates", "Add_a_new_Template"],
    },
  },
  {
    path: "/dashboard/courses/templates/:id",
    name: "Course_Template",
    component: TemplatePage,
    meta: {
      name: "Course Template",
      breadcrumbs: ["Dashboard", "Courses", "Course_Templates", "Course_Template"],
    },
  },
  {
    path: "/dashboard/courses/templates/:id/edit",
    name: "Edit_Course_Template",
    component: EditTemplatePage,
    meta: {
      name: "Edit Course Template",
      breadcrumbs: ["Dashboard", "Courses", "Course_Templates", "Course_Template", "Edit_Course_Template"],
    },
  },
  {
    path: "/dashboard/courses/events",
    name: "Training_Events",
    component: EventsList,
    meta: {
      name: "Training Events",
      breadcrumbs: ["Dashboard", "Courses", "Training_Events"],
    },
  },
  {
    path: "/dashboard/courses/events/new",
    name: "Add_a_new_Event",
    component: NewEvent,
    meta: {
      name: "Add a new Event",
      breadcrumbs: ["Dashboard", "Courses", "Training_Events", "Add_a_new_Event"],
    },
  },
  {
    path: "/dashboard/courses/events/:id",
    name: "Training_Event",
    component: EventPage,
    meta: {
      name: "Training Event",
      breadcrumbs: ["Dashboard", "Courses", "Training_Events", "Training_Event"],
    },
  },
  {
    path: "/dashboard/courses/events/:id/edit",
    name: "Edit_Training_Event",
    component: EditEventPage,
    meta: {
      name: "Edit Training Event",
      breadcrumbs: ["Dashboard", "Courses", "Training_Events", "Training_Event", "Edit_Training_Event"],
    },
  },
  {
    path: "/dashboard/courses/events/:id/bookings/:bookingId",
    name: "User_Order",
    component: OrderPage,
    meta: {
      name: "User Order",
      breadcrumbs: ["Dashboard", "Courses", "Training_Events", "Training_Event", "User_Order"],
    },
  },
];
