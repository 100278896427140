import { Organisation, License, PaginationMeta, Event, Transaction } from "@/types";
import { axiosInstance } from "../clients";

async function listLicenses(data: {
  page: string | number;
  method?: string;
  status?: string;
  user_id?: string;
}): Promise<{ meta: PaginationMeta; data: { licenses: License[]; users: { id: string; name: string; email: string } } }> {
  return (await axiosInstance.get(`/v1/licenses`, { params: data })).data;
}

async function listLicensesByOrg(organisation_id: Organisation["id"]): Promise<{ data: { licenses: License[] } }> {
  return (await axiosInstance.get(`/v1/licenses/${organisation_id}`)).data;
}

async function getLicensesByEvent(
  organisation_id: Organisation["id"],
  event_id: Event["id"]
): Promise<{ data: { available: { total: number; ids: []; allocations: number }; pending: { total: number; ids: []; allocations: number } } }> {
  return (await axiosInstance.get(`/v1/licenses/${organisation_id}/event/${encodeURI(event_id.toString())}`)).data;
}

async function getLicenseById(organisation_id: Organisation["id"], id: License["id"]): Promise<{ data: { license: License } }> {
  return (await axiosInstance.get(`/v1/licenses/${organisation_id}/id/${id}`)).data;
}

async function getLicenseWithTransactionsById(
  organisation_id: Organisation["id"],
  id: License["id"]
): Promise<{
  data: {
    license: License;
    transaction_group: Transaction[];
    TotalGrossPrice: number;
    TotalNetPrice: number;
    TotalTax: number;
    TaxPercentage: number;
    SubscriptionsPurchased: number;
  };
}> {
  return (await axiosInstance.get(`/v1/licenses/${organisation_id}/id/transactions/${id}`)).data;
}

async function toggleLicenseInvoiceIssued(organisation_id: Organisation["id"], id: License["id"]): Promise<{ data: { license: License } }> {
  return (await axiosInstance.post(`/v1/licenses/${organisation_id}/invoice/${id}`)).data;
}

async function toggleInvoiceRefunded(organisation_id: Organisation["id"], id: License["id"]): Promise<{ data: { license: License } }> {
  return (await axiosInstance.post(`/v1/licenses/${organisation_id}/refund/${id}`)).data;
}

async function markLicenseInvoiceAsPaid(organisation_id: Organisation["id"], id: License["id"]): Promise<{ data: { license: License } }> {
  return (await axiosInstance.post(`/v1/licenses/${organisation_id}/paid/${id}`)).data;
}

export default {
  listLicenses,
  listLicensesByOrg,
  getLicensesByEvent,
  getLicenseById,
  getLicenseWithTransactionsById,
  toggleLicenseInvoiceIssued,
  markLicenseInvoiceAsPaid,
  toggleInvoiceRefunded,
};
